import * as React from 'react';
import { useState } from 'react';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
// import { useFlags } from 'launchdarkly-react-client-sdk';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
// import { getStripeKey } from 'lib/utils/stripeUtils';
import { getSiteId } from 'redux/coreSlice';
import { useCreateCheckoutSessionQuery } from 'redux/services/paymentsAPI';
import { useAppSelector } from 'redux/store';
import PageTitle from '../Shell/PageTitle/PageTitle';
import * as S from './Membership.styles';

export type MembershipInfo = {};
export type StripePromise = Stripe | PromiseLike<Stripe | null> | null;

/* Checkout */
export default function Membership() {
  const siteId = useAppSelector(getSiteId);
  const skip = !siteId;
  const {
    data: checkoutSessionOptions /*,
    error,
    isError,
    isLoading, */,
  } = useCreateCheckoutSessionQuery(
    {
      siteId,
    },
    { skip }
  );

  return (
    <S.Membership id="membership-page">
      <PageTitle>Membership</PageTitle>
      <ColumnGrid>
        {checkoutSessionOptions ? (
          <MembershipContent options={checkoutSessionOptions} />
        ) : null}
      </ColumnGrid>
    </S.Membership>
  );
}
/* */

export function MembershipContent({ options }: { options: any }) {
  // const { isDemoSite } = useFlags();

  // TODO: It's possible that this should use `useMemo` instead of using `useState`. Have a look at
  // https://stackoverflow.com/questions/64693509/unsupported-prop-change-on-elements-you-cannot-change-the-stripe-prop-after-s
  const [stripePromise, setStripePromise] = useState<StripePromise>(() =>
    loadStripe(
      'pk_test_m9dsVYjJu18DXGesgEAX5LXK' /* getStripeKey(isDemoSite) */
    )
  );

  // this is junk code so that `setStripePromise` has a use
  if (options['geraniums'] === 'petunias') {
    setStripePromise(null);
  }

  return (
    <S.MembershipContent>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </S.MembershipContent>
  );
  // };
}
