import * as React from 'react';
import { useRef } from 'react';
import { Fireworks } from '@fireworks-js/react';
import type { FireworksHandlers } from '@fireworks-js/react';
import PageTitle from '../Shell/PageTitle/PageTitle';
import * as S from './Membership.styles';

/* MembershipSuccess */
export default function MembershipSuccess() {
  const ref = useRef<FireworksHandlers>(null);

  return (
    <S.Membership id="membership-success-page">
      <PageTitle>Membership Success</PageTitle>
      <h1
        style={{
          display: 'flex',
          fontSize: '240px',
          fontWeight: '700',
          color: 'white',
          zIndex: 1,
        }}
      >
        SUCCESS!
      </h1>
      <Fireworks
        ref={ref}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: '#000',
        }}
        options={{
          autoresize: true,
          opacity: 0.5,
          acceleration: 1.05,
          friction: 0.97,
          gravity: 1.5,
          particles: 50,
          traceLength: 6,
          traceSpeed: 10,
          explosion: 5,
          intensity: 30,
          flickering: 50,
          lineStyle: 'round',
          hue: {
            min: 0,
            max: 360,
          },
          delay: {
            min: 30,
            max: 60,
          },
          rocketsPoint: {
            min: 50,
            max: 50,
          },
          lineWidth: {
            explosion: {
              min: 1,
              max: 3,
            },
            trace: {
              min: 1,
              max: 2,
            },
          },
          brightness: {
            min: 50,
            max: 80,
          },
          decay: {
            min: 0.015,
            max: 0.03,
          },
          mouse: {
            click: false,
            move: false,
            max: 1,
          },
        }}
      />
    </S.Membership>
  );
}
/* */
